<template>
  <div class="card card-custom">
    <div class="card-body">
      <h1>
        {{ $t("dashboard.welcomeToAdmin") }},
        <span class="text-capitalize">{{ currentUserPersonalInfo.name }}</span
        >!
      </h1>
      <p>{{ $t("dashboard.adminShortDescription") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminsWelcomeWidgetsWelcomeWidget",
  computed: {
    ...mapGetters(["currentUserPersonalInfo"])
  }
};
</script>
